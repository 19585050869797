export default {
  loadingChannels               : false,
  channels                      : new Array(),
  addingChannel                 : false,
  channelAdded                  : false,
  channelAddError               : undefined,
  uploadingTriageTranslationFile: false,
  triageTranslationFileUploaded : false,
  updatingChannel               : {
    name                             : false,
    askOrganisationCodeForWeb        : false,
    organisationCode                 : false,
    translationPreferenceId          : false,
    overrideAskOrganisationCodeForWeb: false,
    overrideTranslationPreference    : false,
    enabled                          : false,
    displayName                      : false,
    link                             : false,
    linkDisplayName                  : false,
    consent                          : false,
    description                      : false,
    speakupDescription               : false,
    triageUrl                        : false,
    alternativeDescription           : false,
    draftPrivacyPolicyText           : false,
    publishPrivacyPolicy             : false,
    privacyPolicy                    : false,
    allowLinkDisplayNameTranslations : false,
    enableVoiceMessage               : false,
    receiveVoiceFile                 : false,
    enableAiDigitalAgent             : false
  },
  channelUpdated: {
    name                             : false,
    askOrganisationCodeForWeb        : false,
    organisationCode                 : false,
    translationPreferenceId          : false,
    overrideAskOrganisationCodeForWeb: false,
    overrideTranslationPreference    : false,
    enabled                          : false,
    displayName                      : false,
    link                             : false,
    linkDisplayName                  : false,
    consent                          : false,
    description                      : false,
    speakupDescription               : false,
    triageUrl                        : false,
    alternativeDescription           : false,
    draftPrivacyPolicyText           : false,
    publishPrivacyPolicy             : false,
    privacyPolicy                    : false,
    allowLinkDisplayNameTranslations : false,
    enableVoiceMessage               : false,
    receiveVoiceFile                 : false,
    enableAiDigitalAgent             : false
  },
  channelUpdateError: {
    name                             : false,
    askOrganisationCodeForWeb        : false,
    organisationCode                 : false,
    translationPreferenceId          : false,
    overrideAskOrganisationCodeForWeb: false,
    overrideTranslationPreference    : false,
    displayName                      : false,
    link                             : false,
    linkDisplayName                  : false,
    consent                          : false,
    allowLinkDisplayNameTranslations : false,
    enableVoiceMessage               : false,
    receiveVoiceFile                 : false,
    enableAiDigitalAgent             : false
  },
  formsOfChannels                           : new Object(),
  addingChannelForm                         : false,
  removingChannelForm                       : false,
  loadingForms                              : false,
  translationConfigurations                 : new Object(),
  loadingTranslationConfigurations          : false,
  updatingTranslationConfigurations         : false,
  translationConfigurationsUpdated          : false,
  addingChannelLogo                         : false,
  channelLogoAdded                          : false,
  removingChannelLogo                       : false,
  channelLogoRemoved                        : false,
  downloadingChannelsOverview               : false,
  loadingChannelFormTemplates               : false,
  addingChannelFormTemplates                : false,
  channelFormTemplatesAdded                 : false,
  formTemplatesOfChannels                   : new Object(),
  removingChannelFormTemplate               : false,
  channelFormTemplateRemoved                : false,
  downloadingDescriptionTranslations        : false,
  downloadingTriageTranslations             : false,
  uploadingDescriptionTranslationFile       : false,
  descriptionTranslationFileUploaded        : false,
  draftPrivacyPolicyTranslations            : new Object(),
  privacyPolicyTranslations                 : new Object(),
  loadingPrivacyPolicyTranslations          : false,
  uploadingDraftPrivacyPolicyTranslations   : false,
  draftPrivacyPolicyTranslationsFileUploaded: false,
  downloadingLinkDisplayNameTranslations    : false,
  uploadingLinkDisplayNameTranslationFile   : false,
  linkDisplayNameTranslationFileUploaded    : false
}