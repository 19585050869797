import axios from "axios"
import jsMD5 from "js-md5"
import { AWS_REGION_ID, FILE_TYPE } from "@/constants"
import LOCALES from "@/constants/locales"
import moment from "moment"

/**
 * This method return a list of unique strings from any type of variable.
 * This method will be used to populate autocomplete search fields.
 * @param {*} data that will be input for generation of unique strings.
 * @param {*} excludedKeys object keys to be excluded while constructing unique strings.
 */
export const getListOfStrings = (data, excludedKeys) => {
  const results = new Array()
  if (data !== undefined) {
    if (Array.isArray(data)) {
      for (const item of data) {
        results.push(...getListOfStrings(item, excludedKeys))
      }
    } else if (typeof data === "object") {
      if (data) {
        for (const entry of Object.entries(data)) {
          if (!excludedKeys || !excludedKeys.includes(entry[0])) {
            results.push(...getListOfStrings(entry[1], excludedKeys))
          }
        }
      }
    } else {
      results.push(data.toString())
    }
  }
  return Array.from(new Set(results))
}

/**
 * This method replaces all the placeholders in a string
 * @param data contains the value in which placeholders to be replaced
 * @param args contains list of values for placeholders as an arguments
 */
export const format = (data, ...args) => {
  return data.replace(/((?:[^{}]|(?:\{\{)|(?:\}\}))+)|(?:\{([0-9]+)\})/g, (m, str, index) => {
    if (str) {
      return str.replace(/(?:{{)|(?:}})/g, x => x[0])
    } else {
      if (index < args.length) {
        return args[index]
      }
    }
  })
}

/**
 * This method returns elements in first array which are not present in second array of objects
 * @param {*} firstArray array of objects from which needs to be filtered.
 * @param {*} secondArray array of objects that shouldn't be returned.
 * @param {*} key key based on which difference will be found.
 */
export const getObjectsOnlyInFirstArray = (firstArray, secondArray, key) => {
  const valueOfKeysInSecondArray = secondArray.map(item => item[key])
  return firstArray.filter(item => {
    return !valueOfKeysInSecondArray.includes(item[key])
  })
}

/**
 * This method negates boolean.
 * @param {*} value value to be negated.
 */
export const negateBoolean = value => {
  if (value === undefined || value === null) {
    return value
  } else {
    return !value
  }
}

/**
 * This method will return two character initials for the passed string.
 * @param {*} value to converted to initials.
 */
export const getInitials = value => {
  if (value) {
    const nameParts = value.split(" ")
    let initials
    if (nameParts.length === 1) {
      initials = nameParts[0].charAt(0, 1).toUpperCase()
    } else if (nameParts.length > 1) {
      initials = nameParts[0].charAt(0).toUpperCase() +
        nameParts[nameParts.length - 1].charAt(0).toUpperCase()
    }
    return initials
  }
}

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
*/
export const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === "object"

  return objects.reduce((accumulator, currentValue) => {
    Object.keys(currentValue).forEach(key => {
      const previousVal = accumulator[key]
      const currentVal  = currentValue[key]

      if (Array.isArray(previousVal) && Array.isArray(currentVal)) {
        let tempObject = {}
        previousVal.concat(...currentVal).map((json, index) => {
          tempObject = (index === 0) ? json : mergeDeep(tempObject, json)
        })
        accumulator[key] = [tempObject]
      } else if (isObject(previousVal) && isObject(currentVal)) {
        accumulator[key] = mergeDeep(previousVal, currentVal)
      } else {
        accumulator[key] = currentVal
      }
    })

    return accumulator
  }, {})
}

/**
 * This method will exports json data to csv file.
 * @param {*} filename name for the csv export file.
 * @param {*} arrayOfJson json data to be converted to csv.
 */

export const convertToCSV = (filename, arrayOfJson) => {
  const replacer = (key, value) => value === null || value === undefined ? "" : value.toString() // specify how you want to handle null values here
  if (arrayOfJson?.length) {
    const header = Object.keys(arrayOfJson[0])
    let csv      = arrayOfJson.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","))
    csv.unshift(header.join(","))
    csv = csv.join("\r\n")
    // Create link and download
    var link = document.createElement("a")
    link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv))
    link.setAttribute("download", filename)
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click(); document.body.removeChild(link)
  }
}

/**
 * This method will download a file from a url.
 * @param {*} url url of file to be downloaded.
 * @param {*} downloadName name of file after download.
 */
export const downloadFile = async (url, md5, downloadName, isAudioFile) => {
  const result = await axios.get(url, {
    responseType: "arraybuffer"
  })
  if (!md5 || jsMD5(result.data) === md5) {
    const url = window.URL.createObjectURL(new Blob([result.data]))
    if (isAudioFile) {
      return url
    }
    const link  = document.createElement("a")
    link.href   = url
    link.target = "_blank"
    if (downloadName) {
      link.download = downloadName
    }
    link.click()
  }
}

/**
 * This method copies data from a file url.
 * @param {*} url url of the file.
 * @param {*} md5 md5 of the file.
 */
export const getFileData = async (url, md5) => {
  const result = await axios.get(url, {
    responseType: "arraybuffer"
  })

  if (jsMD5(result.data) === md5) {
    const fileContentInUint8ArrayFormat = new Uint8Array(result.data)
    const fileContentString             = new TextDecoder().decode(fileContentInUint8ArrayFormat)
    const fileContent                   = JSON.parse(fileContentString)
    return fileContent
  }
}

/**
 * This method converts camel case to snake case
 * @param {*} value value to be converted.
 */
export const camelToSnake = value => {
  var result = value.replace(/([A-Z])/g, " $1")
  return result.split(" ").join("_").toLowerCase()
}

export const compareDates = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime()
}

export const compareDatesWithoutTime = (date1, date2) => {
  if (!date1 && !date2) {
    return 0
  } else if (!date1) {
    return 1
  } else if (!date2) {
    return -1
  }
  const date1ValueWithTime = new Date(date1)
  const date2ValueWithTime = new Date(date2)
  return new Date(date1ValueWithTime).getTime() - new Date(date2ValueWithTime).getTime()
}

export const compareArray = (array1, array2) => {
  if (array1 || array2) {
    if (array1?.length === array2?.length) {
      for (const item of array1) {
        if (!array2.includes(item)) {
          return false
        }
      }
    } else {
      return false
    }
  }
  return true
}

/**
 * This method will generate md5 checksum for a file.
 * @param {*} file contains content/details of file.
 */
export const generateMD5ForFile = file  => {
  const reader    = new FileReader()
  const md5Result = new Promise(resolve => {
    reader.onload  = (function(event) {
      resolve(jsMD5(event.target.result))
    })
    reader.onerror = function(event) {
      resolve(event)
    }
    reader.readAsArrayBuffer(file)
  })

  return md5Result
}

export const getMapOfArrayOfObjects = (arrayOfObjects, keyProperty) => {
  const result = new Object()
  for (const item of arrayOfObjects) {
    result[item[keyProperty]] = item
  }
  return result
}

export const mergeArrayOfObjects = (existingItems, currentItems, searchingProperty = "id") => {
  for (const currentItem of currentItems) {
    const index = existingItems.findIndex(existingItem =>
      existingItem?.[searchingProperty] === currentItem[searchingProperty]
    )
    if (index >= 0) {
      existingItems.splice(index, 1, { ...existingItems[index], ...currentItem })
    } else {
      existingItems.push(currentItem)
    }
  }
}

export const getItemsInOriginalOrder = (original, current) => {
  const result = []
  for (const item of original) {
    if (current.includes(item)) {
      result.push(item)
    }
  }
  for (const item of current) {
    if (!original.includes(item)) {
      result.push(item)
    }
  }
  return result
}

export const convertDaysToDuration = days => {
  let countOfMonthsOrYears
  let localeValue
  if (days < 365) {
    countOfMonthsOrYears = Math.round(days / 30.4375 * 10) / 10
    localeValue          = "1283"
  } else {
    countOfMonthsOrYears = Math.round(days / 365.25 * 10) / 10
    localeValue          = "1284"
  }
  return { localeValue, count: countOfMonthsOrYears }
}

/**
 * Saves a value to local storage.
 * @param {string} key - The key under which the value is stored.
 * @param {*} value - The value to be stored. This will be serialized to a JSON string.
 * @throws {Error} If an error occurs during serialization or storage.
 */
export const saveToLocalStorage = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
  } catch (error) {
    throw Error("Error saving to local storage", error)
  }
}

/**
 * Retrieves a value from local storage.
 * @param {string} key - The key under which the value is stored.
 * @returns {*} The retrieved value, deserialized from a JSON string, or null if the key does not exist.
 * @throws {Error} If an error occurs during deserialization or retrieval.
 */
export const getFromLocalStorage = key => {
  try {
    const serializedValue = localStorage.getItem(key)
    return serializedValue ? JSON.parse(serializedValue) : null
  } catch (error) {
    throw Error("Error getting data from local storage", error)
  }
}

/**
 * Deletes a value from local storage.
 * @param {string} key - The key under which the value is stored.
 * @throws {Error} If an error occurs during deletion.
 */
export const deleteFromLocalStorage = key => {
  try {
    localStorage.removeItem(key)
  } catch (error) {
    throw Error("Error deleting data from local storage", error)
  }
}

/**
 * Get the base URL based on the region.
 * @param {string} region - The region for which the base URL is needed.
 * @param {string} [path = ""] - The optional path to be appended to the base URL.
 * @returns {string} - The base URL for the specified region and path.
 */
export const getBaseURL = (region, path = "") => {
  let baseURL
  switch(region) {
    case AWS_REGION_ID.UAE:
      baseURL = process.env.VUE_APP_THEMIS_UAE_API_BASE_URL
      break
    case AWS_REGION_ID.FRANKFURT:
    default:
      baseURL = process.env.VUE_APP_THEMIS_FRANKFURT_API_BASE_URL
      break
  }

  baseURL += path
  return baseURL
}

/**
 * Compares two arrays of strings to check if they are equal.
 *
 * This function checks if both arrays have the same length and if every element
 * in the first array is equal to the corresponding element in the second array.
 *
 * @param {string[]} array1 - The first array to compare.
 * @param {string[]} array2 - The second array to compare.
 * @returns {boolean} - Returns true if both arrays are equal, otherwise false.
 */
export const checkArrayEquality = (array1, array2) => {
  return array1.length === array2.length
    && array1.every((value, index) => value === array2[index])
}

/**
 * This method will return the color class based on due date and status category.
 * @param {*} dueDate due date of the issue.
 * @param {*} isNewOrInprogress the boolean value to check if the issue is new or in progress.
 * @returns {string} - The color based on due date and status.
 */
export const getDueDateColor = (dueDate, isNewOrInprogress) => {
  if (dueDate) {
    if (isNewOrInprogress) {
      const currentDate   = new Date()
      const dueDateObject = new Date(dueDate)

      const timeDifference = dueDateObject - currentDate
      const daysDifference = timeDifference / (1000 * 3600 * 24)
      const dueSoon        = currentDate === dueDateObject || (daysDifference >= 0 && daysDifference < 5)

      if (currentDate > dueDateObject) {
        return "error--text"
      } else if (dueSoon) {
        return "warning--text"
      }
    }
    return "info--darken4"
  }
  return ""
}

/**
 * Checks if two arrays are equal regardless of the order of elements.
 * @param {Array} array1 The first array.
 * @param {Array} array2 The second array.
 * @returns {boolean} True if the arrays are equal, false otherwise.
 */
export const checkArrayEqualityIgnoreOrder = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false
  }

  const sortedArray1 = array1.slice().sort()
  const sortedArray2 = array2.slice().sort()

  return sortedArray1.every((value, index) => value === sortedArray2[index])
}

/**
 * Sorts an array of indices and groups consecutive numbers into ranges.
 *
 * This function takes an array of indices, sorts them in ascending order,
 * and then processes the sorted array to group consecutive numbers into
 * ranges.
 * If a number is not part of a range, it is represented as a single number
 * string. The function returns an array of these string representations.
 *
 * @param {number[]} indices - The array of indices to be sorted and grouped.
 * @returns {string[]} - An array of strings representing the sorted and grouped indices.
 */
export const sortIndices = indices => {
  indices.sort((firstIndex, secondIndex) => firstIndex - secondIndex)

  let start    = indices[0]
  let end      = start
  const result = []

  for (let i = 1; i < indices.length; i++) {
    if (indices[i] === end + 1) {
      // Current number is consecutive
      end = indices[i]
    } else {
      // Current number is not consecutive
      if (start === end) {
        result.push(`${start}`)
      } else {
        result.push(`${start}-${end}`)
      }
      start = indices[i]
      end   = start
    }
  }
  // Handle the last range or single number
  if (start === end) {
    result.push(`${start}`)
  } else {
    result.push(`${start}-${end}`)
  }

  return result
}

/**
 * Checks if two nested objects are equal regardless of the order of elements.
 * @param {Object} object1 The first nested object.
 * @param {Object} object2 The second nested object.
 * @returns {boolean} True if the object keys and values are same and false otherwise.
 */
export const checkObjectEqualityIgnoreOrder = (object1, object2) => {
  if (typeof object1 === "object" && typeof object2 === "object"
    && object1 !== null && object2 !== null) {
    // Get the keys of both objects
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    // Check if both objects have the same number of keys
    if (keys1.length !== keys2.length) return false

    // Recursively check each key
    return keys1.every(key => {
      if (!keys2.includes(key)) return false
      return checkObjectEqualityIgnoreOrder(object1[key], object2[key])
    })
  }

  // If they are arrays, compare them as arrays
  if (Array.isArray(object1) && Array.isArray(object2)) {
    if (object1.length !== object2.length) return false

    // Sort arrays and compare recursively
    const sorted1 = [...object1].sort()
    const sorted2 = [...object2].sort()
    return sorted1.every((value, index) => checkObjectEqualityIgnoreOrder(value, sorted2[index]))
  }

  // For primitive values, compare directly
  return object1 === object2
}

/**
 * Computes and formats log messages based on the provided logs data and mappings.
 *
 * @param {Array} logsData - The array of log data objects to be processed.
 * @param {Object} maps - An object containing mappings for users, statuses, domains, and issue resolutions.
 * @param {Object} maps.usersMap - A mapping of user IDs to user objects.
 * @param {Object} maps.statusesMap - A mapping of status IDs to status objects.
 * @param {Object} maps.domainsMap - A mapping of domain IDs to domain objects.
 * @param {Object} maps.issueResolutionsMap - A mapping of issue resolution IDs to resolution objects.
 * @param {Object} translator - The Vue instance or context containing the $t method for translations.
 * @returns {Array} - An array of formatted log objects.
 */
export const computeLogs = (logsData, { usersMap, statusesMap, domainsMap, issueResolutionsMap }, translator) => {
  const logs = new Array()
  for (const eachLog of logsData) {
    const user                      = usersMap[eachLog.userId]
    const log                       = {
      id                   : eachLog.id,
      user,
      disabledButNotDeleted: user?.disabledButNotDeleted,
      createdAt            : eachLog.createdAt
    }
    const constructValuesLogMessage = (log, mapObject, oldValueKey, newValueKey) => {
      return translator.$t(LOCALES.LOGS[log.event], {
        [oldValueKey]: mapObject[log.data.initialValue] ? mapObject[log.data.initialValue].name : translator.$t("372"),
        [newValueKey]: mapObject[log.data.finalValue] ? mapObject[log.data.finalValue].name : translator.$t("372")
      })
    }
    switch(eachLog.event) {
      case "ISSUE_ACKNOWLEDGED_AT_CHANGE":
      case "ISSUE_RECEIVED_AT_CHANGE": {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
          old: eachLog.data.initialValue ? moment(eachLog.data.initialValue).format("D MMMM YYYY HH:mm (UTCZ)") : translator.$t("372"),
          new: eachLog.data.finalValue ? moment(eachLog.data.finalValue).format("D MMMM YYYY HH:mm (UTCZ)") : translator.$t("372")
        })
        break
      }
      case "ISSUE_ASSIGNEE_CHANGE": {
        log.message = constructValuesLogMessage(
          eachLog,
          usersMap,
          "oldAssignee",
          "newAssignee"
        )
        break
      }
      case "ISSUE_STATUS_CHANGE": {
        log.message = constructValuesLogMessage(
          eachLog,
          statusesMap,
          "oldStatus",
          "newStatus"
        )
        break
      }
      case "ISSUE_DOMAIN_CHANGE": {
        log.message = constructValuesLogMessage(
          eachLog,
          domainsMap,
          "oldDomain",
          "newDomain"
        )
        break
      }
      case "ISSUE_LABELS_CHANGE": {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
          old: eachLog.data.initialValue?.length ? eachLog.data.initialValue : translator.$t("372"),
          new: eachLog.data.finalValue?.length ? eachLog.data.finalValue : translator.$t("372")
        })
        break
      }
      case "ISSUE_RESOLUTION_CHANGE": {
        if (!eachLog.data.finalValue) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].CLEAR)
        } else {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].SET, {
            resolution: issueResolutionsMap[eachLog.data.finalValue] ? issueResolutionsMap[eachLog.data.finalValue].name : translator.$t("372")
          })
        }
        break
      }
      case "ISSUE_INVITE_USER":
      case "ISSUE_REMOVE_USER":
      case "ISSUE_INVITE_GROUP":
      case "ISSUE_REMOVE_GROUP": {
        let name
        if (eachLog.data.otherInformation.userId) {
          name = usersMap[eachLog.data.otherInformation.userId]?.name
        } else {
          name = eachLog.data.otherInformation.groupName
        }
        log.message = translator.$t(
          LOCALES.LOGS[eachLog.event], {
            name,
            role: eachLog.data.otherInformation.roleName,
            toId: eachLog.data.otherInformation.toId
          }
        )
        break
      }
      case "MESSAGE_SENT": {
        if (eachLog.userId) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].CLIENT)
        } else {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].REPORTER)
        }
        break
      }
      case "MESSAGE_SEEN": {
        if (!eachLog.userId) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event])
        }
        break
      }
      case "ISSUE_DATA_RETENTION_SCHEDULED": {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
          timeFrame: moment(eachLog.data.otherInformation.dataRetainedUntil).format("D MMMM YYYY")
        })
        break
      }
      case "ISSUE_DATA_RETAINED_UNTIL_CHANGE": {
        if (!eachLog.data.finalValue) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event])
        }
        break
      }
      case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_TRIGGER": {
        let logMessage = ""
        if (eachLog.data.otherInformation.automationName) {
          logMessage = translator.$t("1385", {
            automationName: eachLog.data.otherInformation.automationName
          })
        } else {
          logMessage = translator.$t("1123", {
            domain: domainsMap[eachLog.data.otherInformation.domainId].name
          })
        }

        log.message = translator.$t(LOCALES.LOGS[eachLog.event], { logMessage })
        break
      }
      case "ISSUE_DUE_DATE_CHANGE": {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
          old: eachLog.data.initialValue ? moment(eachLog.data.initialValue).format("D MMMM YYYY HH:mm (UTCZ)") : translator.$t("1856"),
          new: eachLog.data.finalValue ? moment(eachLog.data.finalValue).format("D MMMM YYYY HH:mm (UTCZ)") : translator.$t("1856")
        })
        break
      }
      case "ISSUE_DOCUMENT_UPDATE":
      case "MESSAGE_ITEM_UPDATE": {
        if ("folderId" in eachLog.data.otherInformation) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].UPDATE_FOLDER, {
            type      : FILE_TYPE.DOCUMENT,
            folderName: eachLog.data.otherInformation.folderName ?? `Issue ${eachLog.entityId}`,
            name      : eachLog.data.otherInformation.name
          })
        } else if (eachLog.data.otherInformation.removed) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].REMOVE, {
            fileName: eachLog.data.otherInformation.name
          })
        } else if (eachLog.data.otherInformation.added) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].ADD, {
            fileName: eachLog.data.otherInformation.name
          })
        } else if (eachLog.data.otherInformation.updated) {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event].EDIT, {
            fileName: eachLog.data.otherInformation.name
          })
        }
        break
      }
      case "ISSUE_DOCUMENT_CREATE": {
        if (eachLog.data.otherInformation?.folderId !== null) {
          log.message = translator.$t("1949", {
            documentName: eachLog.data.otherInformation.name,
            folderName  : eachLog.data.otherInformation.folderName
          })
        } else {
          log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
            name: eachLog.data.otherInformation.name
          })
        }
        break
      }
      case "FOLDER_DELETE":
      case "FOLDER_CREATE": {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
          folderName  : eachLog.data.otherInformation.name,
          parentFolder: eachLog.data.otherInformation.parentFolderName ?? `Issue ${eachLog.entityId}`
        })
        break
      }
      case "FOLDER_MOVE": {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], {
          type      : FILE_TYPE.FOLDER,
          folderName: eachLog.data.otherInformation.parentFolderName ?? `Issue ${eachLog.entityId}`,
          name      : eachLog.data.otherInformation.name
        })
        break
      }
      default: {
        log.message = translator.$t(LOCALES.LOGS[eachLog.event], eachLog.data.otherInformation)
      }
    }
    if (log.message) {
      logs.push(log)
    }
  }
  return logs
}