import { GET_HTTP_CLIENT } from "@/api"
import { HTTP_STATUS_CODE, REPORT_INTAKE_TYPE } from "@/constants"
import { getBaseURL } from "@/utils"

const path = "/v1/messages"

export default {
  /**
   * This action will load a particular message.
   * @param {*} context it is the store.
   * @param {*} payload it is the filter criteria
   */
  async loadMessages(context, payload) {
    context.commit("setLoadingMessages", true)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const getMessagesResponse = await httpClient.get(undefined, {
      params: payload
    })

    if (getMessagesResponse.status === HTTP_STATUS_CODE.OK) {
      if (payload) {
        context.commit("updateMessages", getMessagesResponse.data)
      } else {
        context.commit("setMessages", getMessagesResponse.data)
      }
    } else {
      // something went wrong while getting messages
    }

    context.commit("setLoadingMessages", false)
  },
  /**
   * This action will add a message.
   * @param {*} context is the store.
   */
  async addMessage(context, payload) {
    context.commit("setAddingMessage", true)
    context.commit("setMessageAdded", false)

    const items             = payload.items
    const reportIntakeType  = payload.reportIntakeType
    const shouldCloseReport = payload.shouldCloseReport
    delete payload.shouldCloseReport
    delete payload.reportIntakeType
    delete payload.item

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const postMessageResponse = await httpClient.post(undefined, payload)

    if (postMessageResponse.status === HTTP_STATUS_CODE.OK) {
      context.dispatch("accessControl/loadLoggedInUserAccess", [{
        policies   : ["Message update", "Message remove"],
        resourceIds: [postMessageResponse.data.id]
      }], { root: true })
      if (items) {
        for (const item of items) {
          await context.dispatch("messageItems/addMessageItem", {
            messageId: postMessageResponse.data.id,
            ...item
          }, { root: true })
          const isMessageItemAdded = context.rootGetters["messageItems/isMessageItemAdded"]
          if (isMessageItemAdded) {
            context.commit("updateMessages", [postMessageResponse.data])
            context.commit("setMessageAdded", true)
          } else {
            context.dispatch("removeMessage", postMessageResponse.data.id)
          }
        }
      } else {
        context.commit("updateMessages", [postMessageResponse.data])
        context.commit("setMessageAdded", true)
      }
      if (reportIntakeType === REPORT_INTAKE_TYPE.AI_AGENT && shouldCloseReport) {
        await context.dispatch("updateMessage", {
          id    : postMessageResponse.data.id,
          status: "new"
        })
        await context.dispatch("reports/updateReport", {
          id    : postMessageResponse.data.reportId,
          status: "closed"
        }, { root: true })
      }
    } else if (postMessageResponse.status === HTTP_STATUS_CODE.CONFLICT) {
      context.commit("setMessageAddError", postMessageResponse.data)
    } else {
      // something went wrong while adding a message
    }

    context.commit("setAddingMessage", false)
  },
  /**
   * This action updates summary of an message.
   * @param {*} context is the store.
   * @param {*} payload id and summary of an message.
   */
  async updateMessage(context, message) {
    const id = message.id
    delete message.id
    const item = message.item
    delete message.item

    const updateMessage = async () => {
      const httpClient = GET_HTTP_CLIENT({
        baseURL: getBaseURL(context.rootGetters["auth/region"], path),
        headers: {
          client: context.rootGetters["shared/clientName"]
        }
      }, context)

      const updateMessageResponse = await httpClient.put(`/${id}`, message)

      if (updateMessageResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
        context.dispatch("loadMessages", { id })
        context.commit("setMessageUpdated", propertiesToBeUpdated)
      } else {
        // something went wrong while updating an message
      }
    }

    const propertiesToBeUpdated = Object.keys(message)
    for (const property of propertiesToBeUpdated) {
      if (message[property] === undefined) {
        message[property] = null
      }
    }
    context.commit("setUpdatingMessage", propertiesToBeUpdated)
    context.commit("resetMessageUpdated", propertiesToBeUpdated)

    if (item) {
      await context.dispatch("messageItems/updateMessageItem", {
        id   : item.id,
        value: item.value
      }, { root: true })
      const isMessageItemValueUpdated = context.rootGetters["messageItems/isValueUpdated"]
      if (isMessageItemValueUpdated) {
        if (item.humanTranslateManually) {
          await context.dispatch("translations/addTranslation", {
            messageItemId: item.id,
            type         : "TRANSLATE"
          }, { root: true })
          const isMessageItemHumanTranslated = context.rootGetters["translations/isTranslationAdded"]
          if (isMessageItemHumanTranslated) {
            await updateMessage()
          }
        } else {
          await updateMessage()
        }
      }
    } else {
      await updateMessage()
    }

    context.commit("resetUpdatingMessage", propertiesToBeUpdated)
  },

  /**
   * This action removes a particular message.
   * @param {*} context is the store.
   * @param {*} payload contains id of the message.
   */
  async removeMessage(context, payload) {
    context.commit("setRemovingMessage", true)
    context.commit("setMessageRemoved", false)

    const httpClient = GET_HTTP_CLIENT({
      baseURL: getBaseURL(context.rootGetters["auth/region"], path)
    }, context)

    const deleteMessageResponse = await httpClient.delete(`/${payload}`)

    if (deleteMessageResponse.status === HTTP_STATUS_CODE.OK_WITH_NO_CONTENT) {
      context.commit("removeMessages", [{
        id: payload
      }])
      context.commit("setMessageRemoved", true)
    } else {
      // something went wrong while updating an message
    }

    context.commit("setRemovingMessage", false)
  },

  /**
   * This action is used to reset store.
   * @param {*} context is the store.
   */
  reset(context) {
    context.commit("setMessages", new Array())
    context.commit("setLoadingMessages", undefined)
    context.commit("setMessageAddError", undefined)
    context.commit("resetMessageUpdateError")
    context.commit("resetUpdatingMessage")
    context.commit("resetMessageUpdated")
    context.commit("setAddingMessage", false)
    context.commit("setMessageAdded", false)
    context.commit("setRemovingMessage", false)
    context.commit("setMessageRemoved", false)
  }
}