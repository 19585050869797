import ThemisNotificationSnackbar from "@/components/shared/notification-snackbar"
import ThemisTermsOfUseLogin from "@/components/terms-of-use/login"
import { mapGetters, mapActions } from "vuex"
import { hasAccessToRoute, hasToShowSubModule } from "@/utils/access"
import SUPPORT_MENU_MODULES from "@/constants/support-menu-modules"
import {
  ACCESS_MANAGEMENT_ROUTES,
  FORMS_ROUTES, SYSTEM_ROUTES,
  ISSUE_CUSTOMISATION_ROUTES,
  ISSUE_TYPES,
  ISSUES_ROUTES,
  ANCHOR_LINKS
} from "@/constants"
import { ISSUES_WITH_SAVED_FILTERS_ENABLED } from "@/constants/modules"

export default {
  name      : "Default",
  components: {
    ThemisNotificationSnackbar,
    ThemisTermsOfUseLogin
  },
  data() {
    return {
      module                : null,
      drawer                : true,
      navigationMini        : false,
      sidePanelMini         : false,
      showUserMenu          : false,
      showSupportMenu       : false,
      hasToDisplayTermsOfUse: false,
      progressPercent       : null,
      updateInterval        : null
    }
  },
  computed: {
    ...mapGetters({
      isPageFreezed                                 : "shared/isPageFreezed",
      isNotified                                    : "shared/isNotified",
      breadcrumbs                                   : "shared/breadcrumbs",
      notification                                  : "shared/notification",
      loggedInUser                                  : "auth/loggedInUser",
      isLoggedIn                                    : "auth/isLoggedIn",
      loggedInUserRoleTypes                         : "auth/loggedInUserRoleTypes",
      loggedInUserPolicies                          : "accessControl/loggedInUserPolicies",
      pageTitle                                     : "shared/pageTitle",
      isIssueFormsEnabled                           : "configurations/isIssueFormsEnabled",
      isIssueFieldsEnabled                          : "configurations/isIssueFieldsEnabled",
      isReporterIntakeFormsEnabled                  : "configurations/isReporterIntakeFormsEnabled",
      termsOfUse                                    : "configurations/termsOfUse",
      isUpdatingTermsOfUseAccepted                  : "users/isUpdatingTermsOfUseAccepted",
      isReplyTemplatesEnabled                       : "configurations/isReplyTemplatesEnabled",
      logOutTime                                    : "auth/logOutTime",
      issues                                        : "issues/issues",
      allowedIssuesAndDomainsForIssueFieldViewPolicy: "accessControl/allowedIssuesAndDomainsForIssueFieldViewPolicy",
      isSavedFiltersEnabled                         : "configurations/isSavedFiltersEnabled",
      isProgressBarInitiated                        : "shared/isProgressBarInitiated",
      isProgressBarPromisesPending                  : "shared/isProgressBarPromisesPending",
      tokenIsAboutToExpire                          : "shared/tokenIsAboutToExpire"
    }),
    showSidePanel() {
      if (this.$route.name === "issue" || this.$route.name === "task") {
        const issue   = this.issues.find(issue => issue.id === +this.$route.params.id)
        const isACase = issue.typeId === ISSUE_TYPES[0].id

        if (isACase) {
          const canViewIssueFields =
          this.allowedIssuesAndDomainsForIssueFieldViewPolicy.domainIds.includes(issue.domainId) ||
          this.allowedIssuesAndDomainsForIssueFieldViewPolicy.ids.includes(issue.id)

          if (!issue.statusId && !canViewIssueFields) {
            return false
          }
        } else if (!issue.statusId) {
          return false
        }
      }
      if (this.$route.name === "channel" || this.$route.name === "domain-channel") {
        return this.$route.hash === ANCHOR_LINKS.LINK_DISPLAY_NAME_TRANSLATIONS
          || this.$route.hash === ANCHOR_LINKS.AI_DIGITAL_AGENT_SETTINGS
      }
      return true
    },
    isMediumAndBelowScreen() {
      return this.$vuetify.breakpoint.mdAndDown
    },
    isLoggedInUserAdmin() {
      return this.loggedInUserRoleTypes.includes("global")
    },
    modules() {
      const modules               = [this.$DASHBOARD]
      const loggedInUserHasAccess = module => {
        return hasAccessToRoute(module.roleTypes)
      }
      const shouldShowSubModule   = module => {
        return hasToShowSubModule(module.action)
      }
      if (this.loggedInUserRoleTypes.length) {
        for (let module of this.$MODULES) {
          if (module.title === "890") {
            module.items.forEach(item => {
              if (item.title === "934") {
                if (!this.isIssueFieldsEnabled) {
                  item.action = ["issue-form-templates", "issue-form-template"]
                } else if (!this.isIssueFormsEnabled) {
                  item.action = ["issue-fields", "issue-field"]
                }
              }
            })
          }
          if (module.title === "710") {
            if (!this.loggedInUserPolicies["Analytics view"]) {
              continue
            }
            if (this.checkPropertyInAnalyticsViewWhere("insights")) {
              if (!module.action.includes("analytics-views")) {
                module.action.push("analytics-views")
              }
            }
            if (this.checkPropertyInAnalyticsViewWhere("export")) {
              if (!module.action.includes("analytics-export")) {
                module.action.push("analytics-export")
              }
            }
          }
          const notShowIssues       = !this.loggedInUserPolicies["Issue add"] && !this.loggedInUserPolicies["BulkImport add"]
            && !this.loggedInUserPolicies["IssueSearch view"]
          const showIssuesSubModule = this.isSavedFiltersEnabled && this.loggedInUserPolicies["IssueSearch view"]
            && this.loggedInUserPolicies["SavedFilter view"]
          if (module.title === "413") {
            if (notShowIssues) {
              continue
            }
            if (showIssuesSubModule) {
              module = ISSUES_WITH_SAVED_FILTERS_ENABLED
            }
          }
          if (module.items) {
            const subModules = new Array()
            for (const subModule of module.items) {
              if (loggedInUserHasAccess(subModule) && shouldShowSubModule(subModule)) {
                subModules.push(subModule)
              }
            }
            if (subModules.length > 0) {
              modules.push({
                icon : module.icon,
                title: module.title,
                items: subModules
              })
            }
          } else if (loggedInUserHasAccess(module)) {
            modules.push(module)
          }
        }
      }

      return modules
    },
    pageTitleForDisplay() {
      if (this.pageTitle?.useTranslation) {
        return this.$t(this.pageTitle.value)
      }
      return this.pageTitle?.value
    },
    routeName() {
      return this.$route.name
    },
    termsOfUseValue() {
      return this.termsOfUse?.value
    },
    isAllPromisesResolved() {
      return this.isProgressBarInitiated || this.isProgressBarPromisesPending
    }
  },
  mounted() {
    this.checkLogoutTimeCookie()
  },
  methods: {
    ...mapActions({
      suppressNotification: "shared/suppressNotification",
      updateUser          : "users/updateUser",
      notify              : "shared/notify",
      startLogoutInterval : "shared/startLogoutInterval"
    }),
    checkLogoutTimeCookie() {
      this.startLogoutInterval()
    },

    handleSupportRequest(module) {
      if(module.title === SUPPORT_MENU_MODULES[1].title) {
        if (this.isLoggedInUserAdmin) {
          window.open(module.action)
        } else {
          this.$router.push({ name: "support" })
        }
      } else {
        window.open(module.action)
      }
      this.showSupportMenu = false
    },
    breadcrumbForDisplay(item) {
      if (item.useTranslation) {
        return this.$t(item.text)
      }
      return item.text
    },
    handleClickOnSupportMenu() {
      this.showSupportMenu = !this.showSupportMenu
    },
    handleClickOutsideOnSupportMenu() {
      this.showSupportMenu = false
    },
    handleClickOutsideOnAvatar() {
      this.showUserMenu = false
    },
    handleClickOnAvatar() {
      this.showUserMenu = !this.showUserMenu
    },
    logout() {
      this.$router.push({ name: "logout" })
    },
    acceptTermsOfUse() {
      this.updateUser({
        id                : this.loggedInUser.id,
        termsOfUseAccepted: true
      })
    },
    getBackgroundColorForGroupListIcon(module) {
      const routeName         = this.$route.path.split("/")[1]
      const isSubModuleActive = module.items.find(subModule => {
        if (Array.isArray(subModule.action)) {
          return subModule.action.includes(routeName)
        }
        return subModule.action === routeName
      })
      if (this.navigationMini && !isSubModuleActive) {
        return  "grey"
      } else {
        return  "secondary"
      }
    },
    checkForActiveModule(item) {
      return (ACCESS_MANAGEMENT_ROUTES.includes(this.$route.name) && item.title === "1100") ||
      (ISSUES_ROUTES.includes(this.$route.name) && item.title === "413") ||
      (FORMS_ROUTES.includes(this.$route.name) && item.title === "1101") ||
      (SYSTEM_ROUTES.includes(this.$route.name) && item.title === "1102") ||
      (ISSUE_CUSTOMISATION_ROUTES.includes(this.$route.name) && item.title === "890")
    },
    updateProgress() {
      this.progressPercent = 0
      this.updateInterval  = setInterval(() => {
        if (this.progressPercent < 80) {
          this.progressPercent += 4 // Increase by 4% every 100ms (4% * 20 = 80%)
        } else if (this.progressPercent < 98) {
          this.progressPercent += 1 // Increase by 1% every second
        } else {
          clearInterval(this.updateInterval)
        }
      }, this.progressPercent < 80 ? 100 : 1000)
    },
    getRoute(action) {
      if (Array.isArray(action)) {
        if (action.includes(this.$route.name)) {
          return {
            name: this.$route.name
          }
        } else {
          return {
            name: action[0]
          }
        }
      }
      return {
        name: action
      }
    },
    checkPropertyInAnalyticsViewWhere(property) {
      const analyticsViewPolicies = this.loggedInUserPolicies["Analytics view"]
      return analyticsViewPolicies && analyticsViewPolicies.some(analyticsViewPolicy =>
        analyticsViewPolicy.wheres && analyticsViewPolicy.wheres.some(where => where.type === property)
      )
    }
  },
  watch: {
    routeName: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.sidePanelMini = false
        }
      }
    },
    isMediumAndBelowScreen: {
      immediate: true,
      handler  : function(newValue) {
        this.navigationMini = newValue
        this.sidePanelMini  = newValue
      }
    },
    isLoggedIn: {
      immediate: true,
      handler  : function(newValue) {
        this.hasToDisplayTermsOfUse = newValue &&
          !this.loggedInUser.termsOfUseAccepted &&
          !!this.termsOfUseValue?.length
      }
    },
    "loggedInUser.termsOfUseAccepted": {
      handler: function(newValue) {
        if (newValue) {
          this.hasToDisplayTermsOfUse = false
        }
      }
    },
    "isProgressBarInitiated": {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.updateProgress()
        }
      }
    },
    isAllPromisesResolved: {
      handler: function(newValue) {
        if (!newValue && this.updateInterval) {
          clearInterval(this.updateInterval)
        }
      }
    }
  }
}